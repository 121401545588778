import {
  GraphSearchForm,
  GraphSearchFormValidate,
  HeatmapSearchForm,
  HeatmapSearchFormValidate,
  IExport,
  IExportSciState,
  IFormFilter,
  IGetDsModelHeatMapResponse,
  IGraphForm,
  IGraphSci,
  IGraphSciComment,
  IGraphSciCommentState,
  IGraphSciState,
  IHeatmapData,
  IHeatmapState,
  INodeType,
} from './graph-data-sci.interface';

export const INITIAL_GRAPH_SCI: IGraphSci = {
  nodes: [],
  edges: [],
  initialNodes: [],
  links: [],
};

export const INITIAL_GRAPH_SCI_STATE: IGraphSciState = {
  selected: {} as IGraphSci,
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_NODE_TYPE: INodeType = {
  label: '',
  checked: true,
};

export const INITIAL_GRAPH_SEARCH_FORM: GraphSearchForm = {
  nsbPage: '',
  nodeName: '',
  resend: '',
  countID: 1,
  count: '3',
  filterTypeID: null,
  money: '0',
  money_min: '0',
  money_max: '0',
  range_money: [1, 1],
  frequency: '0',
  frequency_min: '0',
  frequency_max: '0',
  range_frequency: [1, 1],
  topMoneyID: null,
  topFrequencyID: null,
  node_types: [INITIAL_NODE_TYPE],
  layoutID: 0,
  layoutName: '',
  source: [1],
  suspectName: '',
  // NOTE: hard checked hide person
  checkedPerson: true,
};

export const INITIAL_GRAPH_SEARCH_FORM_VALIDATE: GraphSearchFormValidate = {
  nodeName: '',
};

export const INITIAL_HEATMAP_SEARCH_FORM: HeatmapSearchForm = {
  province: '',
  situation: [],
  dateFrom: '',
  dateTo: '',
  filterOptions: [],
};

export const INITIAL_HEATMAP_SEARCH_FORM_VALIDATE: HeatmapSearchFormValidate = {
  province: '',
};

export const INITIAL_EXPORT_SCI: IExport = {
  bankDatas: [],
};

export const INITIAL_EXPORT_SCI_STATE: IExportSciState = {
  selected: {} as IExport,
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_HEATMAP: IHeatmapData = {
  situations: [],
};

export const INITIAL_HEATMAP_STATE: IHeatmapState = {
  selected: {} as IHeatmapData,
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_GRAPH_SCI_COMMENT_STATE: IGraphSciCommentState = {
  selected: {} as IGraphSciComment,
  error: undefined,
  isPageLoading: false,
};

export const INITIAL_GRAPH_FORM: IGraphForm = {
  id: '',
  record_id: '',
  view_id: '',
};

export const INITIAL_FORM_FILTER: IFormFilter = {
  isShowAll: false,
  layoutID: null,
  layoutName: '',
  type: [
    {
      label: 'เลขประจำตัวประชาชน',
      checked: true,
    },
    {
      label: 'เบอร์โทรศัพท์',
      checked: true,
    },
    {
      label: 'บัญชีธนาคาร',
      checked: true,
    },
  ],
};

export const EDsModelHeatMap = [
  {
    value: 0,
    label: 'arrest',
    text: 'บันทึกการจับกุม / ตรวจยึดยาเสพติด',
  },
  {
    value: 1,
    label: 'confiscate',
    text: 'บันทึกการตรวจยึดทรัพย์สิน / เอกสาร',
  },
  {
    value: 2,
    label: 'searching_report',
    text: 'บันทึกการตรวจค้น',
  },
];
