import { StatusAlert } from 'components/ui';
import { StatusAlertNoTimer } from 'components/ui/Alert';
import { AlertStatus } from 'components/ui/enum';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import { reactLocalStorage } from 'reactjs-localstorage';
import { EDateFormat } from 'shared/enum/date-format.enum';
import { ELocalStorage } from 'shared/enum/local-storage.type';
import { IPagination } from 'shared/interfaces';
import {
  CLOSE_CASE_STATUS_ID,
  ICaseFileFolderAllReportGetResponse,
  ICaseFileFolderAllReportSearch,
  ICaseFileFolderArrestSearch,
  ICaseFileFolderConfiscateSearch,
  ICaseFileFolderInvestigationReportGetResponse,
  ICaseFileFolderInvestigationReportSearch,
  ICaseFileFolderSearchingReportSearch,
  ICaseFileForm,
  ICaseFileInvestigation,
  ICaseFileList,
  ICaseFileOfficerForm,
  ICaseFileSearch,
  ICreateCaseFileRequest,
  IGetCaseFileResponse,
  TOfficerTableData,
} from 'shared/modules/case-file';
import { TDataTable } from 'shared/types';
import { dataToParam, uploadFile } from 'shared/utils/api';
import axios from 'shared/utils/axios';
import { dateThaiFormat, getTime } from 'shared/utils/date-format';
import { toQueryString } from 'shared/utils/query-string';
import { ECaseFileAction, TCaseFileActionTypes } from './case-file.type';

// GET /webCaseFile
// NOTE: search case-file
export const searchCaseFile = (data?: ICaseFileSearch, cb?: Function) => {
  // if (data?.startDate) data.startDate = moment(data.startDate).toISOString();
  // if (data?.endDate) data.endDate = moment(data.endDate).toISOString();

  var datas = {
    ...data,
    agencyID: data?.headQuarterID,
    sectorID: data?.divisionID,
    departmentID: data?.policeStationID,
    startDate: moment(data?.startDate).toISOString(),
    endDate: moment(data?.endDate).toISOString(),
    caseID: data?._caseFileID,
  };

  const params = toQueryString(datas);

  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.SEARCH_CASE_FILE_REQUEST });

    try {
      const response = await axios.get('/caseFileFolder', {
        // const response = await axios.get('/webCaseFile', {
        params,
        paramsSerializer: (params) => qs.stringify(params),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const res: TDataTable<ICaseFileList> = {
        ...response.data,
        table: response.data?.table?.map((caseFile: ICaseFileList) => {
          return {
            ...caseFile,
            caseStartDate: dateThaiFormat(caseFile.caseStartDate, EDateFormat.SlashDate),
            createDate: dateThaiFormat(caseFile.createDate, EDateFormat.SlashDate),
          };
        }),
      };
      dispatch(searchCaseFileSuccess(res));
      reactLocalStorage.set(ELocalStorage.CASEFILE_CURRENTPAGE, response.data.currentPage);
      reactLocalStorage.set(ELocalStorage.CASEFILE_PAGESIZE, response.data.pageSize);
      if (cb) cb();
    } catch (error) {
      dispatch(searchCaseFileFailed(error));
    }
  };
};

const searchCaseFileSuccess = (data: TDataTable<ICaseFileList>): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.SEARCH_CASE_FILE_SUCCESS,
    data,
  };
};

const searchCaseFileFailed = (error): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.SEARCH_CASE_FILE_FAILED,
    error,
  };
};

// GET /case-file/{id}
// NOTE: get case-file
export const getCaseFile = (id: string, onNoPermission?: Function, cb?: Function) => {
  return async (dispatch: (arg0: TCaseFileActionTypes) => void) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_REQUEST });

    try {
      const response = await axios.get(`/caseFileFolder/${id}`, {
        // const response = await axios.get(`/webCaseFile/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(getCaseFileSuccess(response.data));
      if (cb) cb();
    } catch (error) {
      dispatch(getCaseFileFailed(id, error, onNoPermission));
    }
  };
};

const getCaseFileSuccess = (data: IGetCaseFileResponse): TCaseFileActionTypes => {
  var _infoList: any = [];
  data.infoList.map((o) => {
    _infoList.push({
      detail: o,
    });
  });
  return {
    type: ECaseFileAction.GET_CASE_FILE_SUCCESS,
    data: {
      area: data.area,
      caseFileRelateds: data.caseFileRelateds,
      caseID: data.caseID,
      caseName: data.caseName,
      caseStatusID: data.caseStatusID,
      detail: data.detail || '',
      id: data.id,
      info: data.info,
      officerLeadList: data.officerLeadList.map((o) => ({
        division: o.divisionName,
        fullName: o.officerLeadFullName,
        headquarter: o.headQuarterName,
        id: o.id,
        mobilePhoneNumber: o.mobilePhoneNumber,
        officerCode: o.officerCode,
        policeStation: o.policeStationName,
        position: o.positionName,
        organization: o.organizationName,
        subDivision: o.subDivisionName,
        divisionId: null,
        headquarterId: null,
        policeStationID: null,
        organizationId: null,
        positionId: null,
        subDivisionId: null,
        no: undefined,
        department: o.departmentName,
        departmentId: null,
      })),
      officerReportList: data.officerReportList.map((o) => ({
        division: o.divisionName,
        fullName: o.officerReportFullName,
        headquarter: o.headQuarterName,
        id: o.id,
        mobilePhoneNumber: o.mobilePhoneNumber,
        officerCode: o.officerCode,
        policeStation: o.policeStationName,
        organization: o.organizationName,
        position: o.positionName,
        subDivision: o.subDivisionName,
        divisionId: null,
        headquarterId: null,
        policeStationID: null,
        organizationId: null,
        positionId: null,
        subDivisionId: null,
        no: undefined,
        department: o.departmentName,
        departmentId: null,
      })),
      caseEndDate: data.caseEndDate,
      caseStartDate: data.caseStartDate,
      interestingThing: data.interestingThing,
      objective: data.objective,
      suggestion: data.suggestion,
      suspects: data.suspects,
      investigateResults: data.investigateResults,
      isEdit: data.isEdit,
      prefixID: data.prefixID,
      attachments: data.attachments,
      newAttachments: null,
      passportNo: '',
      middleName: '',
      nickName: '',
      telephoneNo: '',
      operationReport: data.operationReport,
      reporterRank: data.reporterRank,
      reporterName: data.reporterName,
      reporterDepartment: data.reporterDepartment,
      leaderRank: data.leaderRank,
      leaderName: data.leaderName,
      leaderDepartment: data.leaderDepartment,
      supervisor1_Rank: data.supervisor1_Rank,
      supervisor1_Name: data.supervisor1_Name,
      supervisor1_Department: data.supervisor1_Department,
      supervisor2_Rank: data.supervisor2_Rank,
      supervisor2_Name: data.supervisor2_Name,
      supervisor2_Department: data.supervisor2_Department,
      start_date: moment(),
      end_date: moment(),
      infoList: [''],
      _infoList: _infoList,
      headQuarterID: null,
      divisionID: null,
      subDivisionID: null,
      departmentID: null,
      policeStationID: null,
      organizationID: null,
      year: moment(),
      // _year: moment(),
      arrestDate: null,
    },
  };
};

const getCaseFileFailed = (id: string, error, onNoPermission?: Function): TCaseFileActionTypes => {
  if (onNoPermission && error.response?.status === 403) onNoPermission();
  return {
    type: ECaseFileAction.GET_CASE_FILE_FAILED,
    error,
    id,
  };
};

// GET /user/{id}
// NOTE: get user {id}
export const getUser = (id: string, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_USER_REQUEST });

    try {
      const response = await axios.get(`/user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(getUserSuccess(response.data));

      if (cb) cb();
    } catch (error) {
      dispatch(getUserFailed(error));
    }
  };
};

const getUserSuccess = (data: ICaseFileOfficerForm): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.GET_USER_SUCCESS,
    data,
  };
};

const getUserFailed = (error): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.GET_USER_FAILED,
    error,
  };
};

// POST /webCaseFile
// NOTE: create case-file
export const createCaseFile = (data: ICaseFileForm, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.CREATE_CASE_FILE_REQUEST });

    try {
      if (!_.isEmpty(data.suspects.map((s) => s.imageFile))) {
        data.suspects = await Promise.all(
          data.suspects.map(async (s) => {
            if (!_.isNil(s.imageFile)) {
              const profileImageUrl = await uploadFile({
                routeName: 'caseFile.suspect',
                attachments: [{ file: s.imageFile }],
              });

              if (!_.isEmpty(profileImageUrl)) {
                s.profileImgUrl = profileImageUrl[0];
              }

              delete s.imageFile;

              return s;
            }

            return s;
          })
        );
      }
      if (Array.isArray(data.newAttachments) && data.newAttachments.length) {
        const uploadedFile = await uploadFile({
          routeName: 'caseFile',
          attachments: data.newAttachments,
        });

        if (!_.isEmpty(uploadedFile)) {
          data.attachments = data.attachments?.concat(uploadedFile);
        }
      }

      const payload: ICreateCaseFileRequest = {
        // const payload = {
        area: data.area,
        caseID: data.caseID,
        caseName: data.caseName,
        caseStatusID: data.caseStatusID || 0,
        info: data.info,
        // officerLeadList: data.officerLeadList.map((o, index) => ({
        //   divisionID: o.divisionId,
        //   headQuarterID: o.headquarterId,
        //   mobilePhoneNumber: o.mobilePhoneNumber,
        //   no: index,
        //   officerCode: o.officerCode,
        //   officerLeadFullName: o.fullName,
        //   id: o.id,
        //   policeStationID: o.policeStationID,
        //   positionID: o.positionId,
        //   subDivisionID: o.subDivisionId,
        //   departmentID: o.departmentId,
        //   departmentName: o.department,
        //   divisionName: o.division,
        //   headQuarterName: o.headquarter,
        //   policeStationName: o.policeStation,
        //   positionName: o.position,
        //   subDivisionName: o.subDivision,
        // })),
        // officerReportList: data.officerReportList.map((o, index) => ({
        //   divisionID: o.divisionId,
        //   headQuarterID: o.headquarterId,
        //   mobilePhoneNumber: o.mobilePhoneNumber,
        //   no: index,
        //   officerCode: o.officerCode,
        //   officerReportFullName: o.fullName,
        //   id: o.id,
        //   policeStationID: o.policeStationID,
        //   positionID: o.positionId,
        //   subDivisionID: o.subDivisionId,
        //   departmentID: o.departmentId,
        //   departmentName: o.department,
        //   divisionName: o.division,
        //   headQuarterName: o.headquarter,
        //   policeStationName: o.policeStation,
        //   positionName: o.position,
        //   subDivisionName: o.subDivision,
        // })),
        suspects: data.suspects.map((suspect) => {
          return { ...suspect, suspectIDCard: suspect.suspectIDCard?.replaceAll('-', '') };
        }),
        caseStartDate: moment(data.caseStartDate).toISOString(),
        caseEndDate: data.caseStatusID === CLOSE_CASE_STATUS_ID ? moment(data.caseEndDate).toISOString() : null,
        detail: data.detail,
        interestingThing: data.interestingThing,
        objective: data.objective,
        suggestion: data.suggestion,
        investigateResults: [],
        caseFileRelateds: [],
        prefixID: data.prefixID,
        attachments: data.attachments,

        // infoList: [],
        operationReport: data.operationReport,
        reporterRank: data.reporterRank,
        reporterName: data.reporterName,
        reporterDepartment: data.reporterDepartment,
        leaderRank: data.leaderRank,
        leaderName: data.leaderName,
        leaderDepartment: data.leaderDepartment,
        supervisor1_Rank: data.supervisor1_Rank,
        supervisor1_Name: data.supervisor1_Name,
        supervisor1_Department: data.supervisor1_Department,
        supervisor2_Rank: data.supervisor2_Rank,
        supervisor2_Name: data.supervisor2_Name,
        supervisor2_Department: data.supervisor2_Department,
        createdByAgencyID: data.headQuarterID,
        createdBySectorID: data.divisionID,
        createdByDepartmentID: data.policeStationID,
      };

      // const _infoList: any = [];

      // data._infoList.map((o) => {
      //   _infoList.push(o.detail);
      // });

      // payload.infoList = _infoList;
      const params = JSON.stringify(payload);
      const response = await axios.post('/caseFileFolder', params, {
        // const response = await axios.post('/webCaseFile', params, {
        // const response = await axios.post('', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(createCaseFileSuccess(response.data.caseID, cb));
    } catch (error) {
      dispatch(createCaseFileFailed(error));
    }
  };
};

const createCaseFileSuccess = (generatedCaseFileId: string, cb?: Function): TCaseFileActionTypes => {
  const baseMessage = i18next.t('case_file:alert.success.save.base', 'บันทึกแฟ้มคดีเลขที่');
  const endMessage = i18next.t('case_file:alert.success.save.end', 'สำเร็จ');

  StatusAlertNoTimer(`${baseMessage} ${generatedCaseFileId} ${endMessage}`, AlertStatus.Success).then(() => {
    if (cb) cb();
  });

  return {
    type: ECaseFileAction.CREATE_CASE_FILE_SUCCESS,
  };
};

const createCaseFileFailed = (error): TCaseFileActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: ECaseFileAction.CREATE_CASE_FILE_FAILED,
    error,
  };
};

// Clear selected case file
// NOTE: clear state
export const clearCaseFileForm = (): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.CLEAR_CASE_FILE_FORM,
  };
};

// PUT /webCaseFile/{id}
// NOTE: update case-file
export const editCaseFile = (id: string) => (data: ICaseFileForm, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.EDIT_CASE_FILE_REQUEST });
    try {
      if (!_.isEmpty(data.suspects.map((s) => s.imageFile))) {
        data.suspects = await Promise.all(
          data.suspects.map(async (s) => {
            if (!_.isNil(s.imageFile)) {
              const profileImageUrl = await uploadFile({
                routeName: 'caseFile.suspect',
                attachments: [{ file: s.imageFile }],
              });

              if (!_.isEmpty(profileImageUrl)) {
                s.profileImgUrl = profileImageUrl[0];
              }

              delete s.imageFile;

              return s;
            }

            return s;
          })
        );
      }

      if (Array.isArray(data.newAttachments) && data.newAttachments.length) {
        const uploadedFile = await uploadFile({
          routeName: 'arrest',
          attachments: data.newAttachments,
        });

        if (!_.isEmpty(uploadedFile)) {
          data.attachments = data.attachments?.concat(uploadedFile);
        }
      }

      const payload: ICreateCaseFileRequest = {
        // const payload = {
        area: data.area,
        caseID: data.caseID,
        caseName: data.caseName,
        caseStatusID: data.caseStatusID || 0,
        info: data.info,
        // officerLeadList: data.officerLeadList.map((o, index) => ({
        //   divisionID: o.divisionId,
        //   headQuarterID: o.headquarterId,
        //   mobilePhoneNumber: o.mobilePhoneNumber,
        //   no: index,
        //   officerCode: o.officerCode,
        //   officerLeadFullName: o.fullName,
        //   id: o.id,
        //   policeStationID: o.policeStationID,
        //   positionID: o.positionId,
        //   subDivisionID: o.subDivisionId,
        //   departmentID: o.departmentId,
        //   departmentName: o.department,
        //   divisionName: o.division,
        //   headQuarterName: o.headquarter,
        //   policeStationName: o.policeStation,
        //   organizationID: o.organizationId,
        //   positionName: o.position,
        //   subDivisionName: o.subDivision,
        // })),
        // officerReportList: data.officerReportList.map((o, index) => ({
        //   divisionID: o.divisionId,
        //   headQuarterID: o.headquarterId,
        //   mobilePhoneNumber: o.mobilePhoneNumber,
        //   no: index,
        //   officerCode: o.officerCode,
        //   officerReportFullName: o.fullName,
        //   id: o.id,
        //   policeStationID: o.policeStationID,
        //   positionID: o.positionId,
        //   subDivisionID: o.subDivisionId,
        //   departmentID: o.departmentId,
        //   departmentName: o.department,
        //   divisionName: o.division,
        //   headQuarterName: o.headquarter,
        //   policeStationName: o.policeStation,
        //   organizationID: o.organizationId,
        //   positionName: o.position,
        //   subDivisionName: o.subDivision,
        // })),
        suspects: data.suspects.map((suspect) => {
          return { ...suspect, suspectIDCard: suspect.suspectIDCard?.replaceAll('-', '') };
        }),
        caseStartDate: moment(data.caseStartDate).toISOString(),
        caseEndDate: data.caseStatusID === CLOSE_CASE_STATUS_ID ? moment(data.caseEndDate).toISOString() : null,
        detail: data.detail,
        interestingThing: data.interestingThing,
        objective: data.objective,
        suggestion: data.suggestion,
        investigateResults: [],
        caseFileRelateds: [],
        prefixID: data.prefixID,
        attachments: data.attachments,

        // infoList: [],
        operationReport: data.operationReport,
        reporterRank: data.reporterRank,
        reporterName: data.reporterName,
        reporterDepartment: data.reporterDepartment,
        leaderRank: data.leaderRank,
        leaderName: data.leaderName,
        leaderDepartment: data.leaderDepartment,
        supervisor1_Rank: data.supervisor1_Rank,
        supervisor1_Name: data.supervisor1_Name,
        supervisor1_Department: data.supervisor1_Department,
        supervisor2_Rank: data.supervisor2_Rank,
        supervisor2_Name: data.supervisor2_Name,
        supervisor2_Department: data.supervisor2_Department,
        createdByAgencyID: data.headQuarterID,
        createdBySectorID: data.divisionID,
        createdByDepartmentID: data.policeStationID,
      };

      // const _infoList: any = [];

      // data._infoList.map((o) => {
      //   _infoList.push(o.detail);
      // });

      // payload.infoList = _infoList;

      await axios.put(`/caseFileFolder/${id}`, JSON.stringify(payload), {
        // await axios.put(`/webCaseFile/${id}`, JSON.stringify(payload), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      dispatch(editCaseFileSuccess(cb));
    } catch (error) {
      dispatch(editCaseFileFailed(error));
    }
  };
};

const editCaseFileSuccess = (cb?: Function): TCaseFileActionTypes => {
  StatusAlert(i18next.t('main:alert.success.save', 'บันทึกสำเร็จ'), AlertStatus.Success).then(() => {
    if (cb) cb();
  });

  return {
    type: ECaseFileAction.EDIT_CASE_FILE_SUCCESS,
  };
};

const editCaseFileFailed = (error): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.EDIT_CASE_FILE_FAILED,
    error,
  };
};

// GET /investigation
// NOTE: get web-investigation {id}
export const getCaseFileInvestigationByCaseFileId = (
  caseFileForm: ICaseFileForm,
  caseFileId: string,
  data?: IPagination,
  cb?: Function
) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_INVESTIGATION_REQUEST });
    try {
      const response = await axios.get('/webInvestigation', {
        // const response = await axios.get('', {
        params: toQueryString({
          caseFileId: caseFileId,
          currentPage: data?.currentPage,
          pageSize: data?.pageSize,
          startDate: moment(caseFileForm?.start_date).toISOString(),
          endDate: moment(caseFileForm?.end_date).toISOString(),
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const res: TDataTable<ICaseFileInvestigation> = {
        ...response.data,
        table: response.data?.table?.map((investigation: ICaseFileInvestigation) => {
          return {
            ...investigation,
            investigationDate: dateThaiFormat(investigation.investigationDate, EDateFormat.FullDate),
            investigationTime: getTime(investigation.investigationDate, EDateFormat.FullTimeWithoutSuffix),
            checkBox: false,
          };
        }),
      };
      dispatch(getCaseFileInvestigationByCaseFileIdSuccess(res, cb));
    } catch (error) {
      dispatch(getCaseFileInvestigationByCaseFileIdFailed(error));
    }
  };
};

const getCaseFileInvestigationByCaseFileIdSuccess = (
  data: TDataTable<ICaseFileInvestigation>,
  cb?: Function
): TCaseFileActionTypes => {
  if (cb) cb();

  return {
    type: ECaseFileAction.GET_INVESTIGATION_SUCCESS,
    data,
  };
};

const getCaseFileInvestigationByCaseFileIdFailed = (error): TCaseFileActionTypes => {
  StatusAlert(error.message, AlertStatus.Error);

  return {
    type: ECaseFileAction.GET_INVESTIGATION_FAILED,
    error,
  };
};

// export const getCaseFilePdf = (id: string, cb?: Function) => {
//   return (dispatch) => {
//     dispatch({ type: ECaseFileAction.GET_PDF_REQUEST });
//     axios
//       .get(`/caseFile/generatePDF/${id}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`,
//         },
//       })
//       .then((response) => {
//         dispatch(getCaseFilePdfSuccess(response.data, cb));
//       })
//       .catch((error) => {
//         dispatch(getCaseFilePdfFailed(error));
//       });
//   };
// };

// const getCaseFilePdfSuccess = (url: string, cb?: Function): TCaseFileActionTypes => {
//   if (cb) cb();
//   return {
//     type: ECaseFileAction.GET_PDF_SUCCESS,
//     fileUrl: url,
//   };
// };

// const getCaseFilePdfFailed = (error: Error): TCaseFileActionTypes => {
//   StatusAlert(i18next.t(error.message), AlertStatus.Error);
//   return {
//     type: ECaseFileAction.GET_PDF_FAILED,
//     error,
//   };
// };

export const clearCaseFileUser = (): TCaseFileActionTypes => ({
  type: ECaseFileAction.CLEAR_CASE_FILE_USER,
});

export const getInitialOfficer = (cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_INITIAL_OFFICER_REQUEST });

    try {
      const { data } = await axios.get('/user/getUserInfo', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const officerData: TOfficerTableData = {
        id: data.id,
        fullName: `${data.firstName} ${data.lastName}`,
        position: data.positionName,
        positionId: data.positionID,
        headquarter: data.headQuarterName,
        headquarterId: data.headQuarterID,
        division: data.divisionName,
        divisionId: data.divisionID,
        policeStation: data.policeStationName,
        policeStationID: data.policeStationID,
        organization: data.organizationName,
        organizationId: data.organizationID,
        subDivision: data.subDivisionName,
        subDivisionId: data.subDivisionID,
        department: data.departmentName,
        departmentId: data.departmentID,
        mobilePhoneNumber: data.mobilePhoneNumber,
        officerCode: data.officerCode,
      };

      dispatch(getInitialOfficerSuccess(officerData, cb));
    } catch (error) {
      dispatch(getInitialOfficerFailed(error));
    }
  };
};

const getInitialOfficerSuccess = (initialOfficer: TOfficerTableData, cb?: Function): TCaseFileActionTypes => {
  if (cb) cb();

  return {
    type: ECaseFileAction.GET_INITIAL_OFFICER_SUCCESS,
    initialOfficer,
  };
};

const getInitialOfficerFailed = (error): TCaseFileActionTypes => {
  return {
    type: ECaseFileAction.GET_INITIAL_OFFICER_FAILED,
    error,
  };
};

// NOTE: delete case-file
export const deleteCaseFile = (id: string, cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.DELETE_CASE_FILE_REQUEST });
    try {
      await axios.delete(`/caseFileFolder/${id}`, {
        // await axios.delete(`/webCaseFile/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      dispatch(deleteCaseFileSuccess(cb));
    } catch (error) {
      dispatch(deleteCaseFileFailed(error));
    }
  };
};

const deleteCaseFileSuccess = (cb?: Function) => {
  StatusAlert(i18next.t('main:alert.success.delete'), AlertStatus.Warning).then(() => {
    if (cb) cb();
  });
  return {
    type: ECaseFileAction.DELETE_CASE_FILE_SUCCESS,
  };
};

const deleteCaseFileFailed = (error) => {
  return {
    type: ECaseFileAction.DELETE_CASE_FILE_FAILED,
    error,
  };
};

// NOTE: get case-file {id} all-report
export const getCaseFileFolderAllReport = (formSearch: ICaseFileFolderAllReportSearch, cb?: Function) => {
  const param = {
    currentPage: formSearch.currentPage,
    pageSize: formSearch.pageSize,
  };
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_REQUEST });
    axios
      .get(`/caseFileFolder/${formSearch.id}/AllReport` + dataToParam(param), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileFolderAllReportSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileFolderAllReportFailed(error)));
  };
};

const getCaseFileFolderAllReportSuccess = (data: ICaseFileFolderAllReportGetResponse, cb?: Function) => {
  if (cb) cb();
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS,
    data,
  };
};

const getCaseFileFolderAllReportFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_ALL_REPORT_SUCCESS,
    error,
  };
};

// NOTE: get case-file {id} investigation-report
export const getCaseFileFolderInvestigationReport = (
  formSearch: ICaseFileFolderInvestigationReportSearch,
  cb?: Function
) => {
  const param = {
    currentPage: formSearch.currentPage,
    pageSize: formSearch.pageSize,
  };
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_REQUEST });
    axios
      .get(`/caseFileFolder/${formSearch.id}/InvestigationReport` + dataToParam(param), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileFolderInvestigationReportSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileFolderInvestigationReportFailed(error)));
  };
};

const getCaseFileFolderInvestigationReportSuccess = (
  data: ICaseFileFolderInvestigationReportGetResponse,
  cb?: Function
) => {
  if (cb) cb();
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS,
    data,
  };
};

const getCaseFileFolderInvestigationReportFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS,
    error,
  };
};

// NOTE: get case-file {id} arrest
export const getCaseFileFolderArrest = (formSearch: ICaseFileFolderArrestSearch, cb?: Function) => {
  const param = {
    currentPage: formSearch.currentPage,
    pageSize: formSearch.pageSize,
  };
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_REQUEST });
    axios
      .get(`/caseFileFolder/${formSearch.id}/Arrest` + dataToParam(param), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileFolderArrestSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileFolderArrestFailed(error)));
  };
};

const getCaseFileFolderArrestSuccess = (data: ICaseFileFolderInvestigationReportGetResponse, cb?: Function) => {
  if (cb) cb();
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_SUCCESS,
    data,
  };
};

const getCaseFileFolderArrestFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_ARREST_SUCCESS,
    error,
  };
};

// NOTE: get case-file {id} confiscate
export const getCaseFileFolderConfiscate = (formSearch: ICaseFileFolderConfiscateSearch, cb?: Function) => {
  const param = {
    currentPage: formSearch.currentPage,
    pageSize: formSearch.pageSize,
  };
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_REQUEST });
    axios
      .get(`/caseFileFolder/${formSearch.id}/Confiscate` + dataToParam(param), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileFolderConfiscateSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileFolderConfiscateFailed(error)));
  };
};

const getCaseFileFolderConfiscateSuccess = (data: ICaseFileFolderInvestigationReportGetResponse, cb?: Function) => {
  if (cb) cb();
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS,
    data,
  };
};

const getCaseFileFolderConfiscateFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_CONFISCATE_SUCCESS,
    error,
  };
};

// NOTE: get case-file {id} searching-report
export const getCaseFileFolderSearchingReport = (formSearch: ICaseFileFolderSearchingReportSearch, cb?: Function) => {
  const param = {
    currentPage: formSearch.currentPage,
    pageSize: formSearch.pageSize,
  };
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_REQUEST });
    axios
      .get(`/caseFileFolder/${formSearch.id}/SearchingReport` + dataToParam(param), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileFolderSearchingReportSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileFolderSearchingReportFailed(error)));
  };
};

const getCaseFileFolderSearchingReportSuccess = (
  data: ICaseFileFolderInvestigationReportGetResponse,
  cb?: Function
) => {
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_SEARCHING_REPORT_SUCCESS,
    data,
  };
};

const getCaseFileFolderSearchingReportFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_FOLDER_INVESTIGATION_REPORT_SUCCESS,
    error,
  };
};

// NOTE: get case-file template
export const getCaseFileTemplate = (cb?: Function) => {
  return async (dispatch) => {
    dispatch({ type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_REQUEST });
    axios
      .get(`/CaseFileFolder/GetTemplateCaseFile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => dispatch(getCaseFileTemplateSuccess(response.data, cb)))
      .catch((error) => dispatch(getCaseFileTemplateFailed(error)));
  };
};

const getCaseFileTemplateSuccess = (data: string, cb?: Function) => {
  if (cb) cb(data);
  return {
    type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_SUCCESS,
    data,
  };
};

const getCaseFileTemplateFailed = (error: Error) => {
  StatusAlert(i18next.t('main:alert.failed'), AlertStatus.Error);
  return {
    type: ECaseFileAction.GET_CASE_FILE_TEMPLATE_FAILED,
    error,
  };
};
