import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from 'router/UnAuthenticated.router';
import './assets/zoomchart/leaflet.css';
import './assets/zoomchart/zc.css';
import './i18n';
import './index.scss';
import './locale-moment';
import * as serviceWorker from './serviceWorker';
import store from './store';
// import withClearCache from './ClearCache';
// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import { DetectScreenCapture } from 'components/ui/Alert';
import UpdateVersion from 'router/UpdateVersion';

// const ClearCacheComponent = withClearCache(AppRouter);

const AppWithRouter: React.FC = () => {
  // const { isLatestVersion } = useClearCacheCtx();
  const isLatestVersion = true;

  useEffect(() => {
    window.addEventListener('keyup', (e) => {
      if (e.key === 'PrintScreen') {
        DetectScreenCapture();
        navigator.clipboard.writeText('');
      }
    });
  }, []);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };
  clearCacheData();

  return (
    <>
      {!isLatestVersion ? (
        <UpdateVersion />
      ) : (
        <Provider store={store}>
          <BrowserRouter>
            <AppRouter />
            {/* <ClearCacheComponent /> */}
          </BrowserRouter>
        </Provider>
      )}
    </>
  );
};

// ReactDOM.hydrate(<AppWithRouter />, document.getElementById('root'));

ReactDOM.render(
  // <ClearCacheProvider duration={50000}>
  <AppWithRouter />,
  // </ClearCacheProvider>,
  document.getElementById('root')
);

serviceWorker.register();
