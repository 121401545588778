import React, { FC } from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo.svg';
import { AppName, UserProfilePortal } from './HeaderPortalComponents';
import constants from 'shared/constants';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: #0b2545;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT}px;
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const AuthenticatedHeaderPortal: FC = () => {
  return (
    <HeaderWrapper>
      <AppIcon src={Logo} />
      <Wrapper>
        <AppName />
        <UserProfilePortal />
      </Wrapper>
    </HeaderWrapper>
  );
};

export default AuthenticatedHeaderPortal;
