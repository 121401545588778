import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IGraphPhoneUploadSearch } from '.';
import {
  IColumn,
  IFile,
  IMatch,
  IModalPhoneUploadCheckForm,
  IModalPhoneUploadRemarkForm,
  IPhoneUploadForm,
  IPhoneUploadFormValidate,
  IPhoneUploadSearch,
  IPhoneUploadState,
  ISheet,
} from './phone-upload.interface';

export const INITIAL_PHONE_UPLOAD_STATE: IPhoneUploadState = {
  all: INITIAL_DATA_TABLE_TYPE,
  selected: {} as IModalPhoneUploadRemarkForm,
  error: undefined,
  isPageLoading: false,
  isTableLoading: false,
  remark: {},
  isRemarkLoading: false,
};

export const INITIAL_FORM_PHONE_UPLOAD: IPhoneUploadForm = {
  searchKeyword: '',
  caseNo: null,
  caseName: '',
  telephoneNo: '',
  providerID: null,
  providerName: '',
  registryStatus: 1,
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  fileName: '',
  attachment: null,
  ownerTitleID: null,
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  etlType: 0,
  etlTypeName: '',
  imageFile: undefined,
  profileImg: undefined,
  imageFileOwner: undefined,
  profileImgOwner: undefined,
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: null,
  isEnableDownload: null,
  formRequestPhoneID: '',
  //
  year: moment(new Date()),
};

export const INITIAL_FORM_PHONE_UPLOAD_VALIDATE: IPhoneUploadFormValidate = {
  telephoneNo: '',
  providerID: '',
  idCard: '',
  firstName: '',
  lastName: '',
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
};

export const INITIAL_PHONE_UPLOAD_SEARCH: IPhoneUploadSearch = {
  keyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  // ;; more filter
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
};

export const INITIAL_GRAPH_PHONE_UPLOAD_SEARCH: IGraphPhoneUploadSearch = {
  idList: [],
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
};

export const INITIAL_MATCH: IMatch = {
  file: '',
  system: '',
};

export const INITIAL_COLUMN: IColumn = {
  match: [[INITIAL_MATCH]],
  ignore: [''],
  missing: [''],
  status: '',
};

export const INITITAL_SHEET: ISheet = {
  sheetName: '',
  column: INITIAL_COLUMN,
};

export const INITIAL_RESPONSE_CHECK_FILE: IFile = {
  fileName: '',
  sheet: [INITITAL_SHEET],
};

export const INITIAL_FORM_MODAL_PHONE_UPLOAD_MODAL_CHECK: IModalPhoneUploadCheckForm = {
  id: '',
  data: [INITIAL_RESPONSE_CHECK_FILE],
  columnMain: [[]],
  columnMissing: [[]],
  time: '',
  loading: true,
  status: '',
};

export const INITIAL_FORM_MODAL_PHONE_UPLOAD_MODAL_REMARK: IModalPhoneUploadRemarkForm = {
  id: '',
  caseNo: '',
  caseName: '',
  telephoneNo: '',
  providerID: null,
  providerName: '',
  provider: '',
  registryStatus: 0,
  registerName: '',
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  ownerIDCard: '',
  ownerTitleID: null,
  ownerTitleName: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  fileName: '',
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: null,
  isEnableDownload: null,
  etlType: '',
  isEditable: false,
  imageFile: undefined,
  imageFileOwner: undefined,
};
