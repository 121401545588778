import moment from 'moment';
import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces';
import { TReportReciveBank } from 'store/recive-bank/recive-bank.type';
import { INITIAL_PROPERTY_BANK, INITIAL_RECIVE_BANK_VALIDATE, INITIAL_RECIVE_PERSON_VALIDATE } from '../property';
import {
  IReciveBankSearch,
  IReportReciveBank,
  IReportReciveBankList,
  IReportReciveBankModalFileUploadBank,
  IReportReciveBankModalFileUploadBankValidate,
  IReportReciveBankState,
  IReportReciveBankValidate,
} from './recive-bank.interface';

export const INITIAL_RECIVE_BANK_SEARCH: IReciveBankSearch = {
  keyword: '',
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
  startDate: null,
  endDate: null,
  status: null,
  statusTemp: null,
};

export const INITIAL_REPORT_RECIVE_BANK: IReportReciveBank = {
  id: '',
  requestBankID: '',
  formTo: '',
  property: INITIAL_PROPERTY_BANK,
  bookNumber: '',
  bookDate: moment(new Date()),
  bookAgencyID: null,
  commanderDate: moment(new Date()),
  commanderSectorID: null,
  commanderDepartmentID: null,
  commanderAgencyID: null,
  recipientDate: moment(new Date()),
  recipientSectorID: null,
  recipientDepartmentID: null,
  recipientAgencyID: null,
  inviteDate: moment(new Date()),
  inviteSectorID: null,
  inviteDepartmentID: null,
  inviteAgencyID: null,
  requesterName: '',
  requesterPhone: '',
  requesterMore: '',
  checkType: 0,
  inviteName: null,
  recipientName: null,
  checkBankList: [INITIAL_RECIVE_BANK_VALIDATE],
  fileAttactment: [] as IUploadFileResponse[],
  attachments: null,
  fileName: null,
  newAttachments: null,
  fileNameAtt: '',
  commander: null,
  firstName: '',
  lastName: '',
  idCard: '',
  detail: '',
  detailCase: '',
  detailCheck: '',
  remark: '',
  requestDetail: '',
  circumstance: '',
  headQuarterID: null, // agency
  divisionID: null, // sector
  subDivisionID: null,
  policeStationID: null, // department
  departmentID: null,
  organizationID: null,
  // bookDepartmentID: TSelect;
  bookSectorID: null,
  bookHeadQuarterID: null,
  bookDivisionID: null,
  bookSubDivisionID: null,
  bookDepartmentID: null,
  bookPoliceStationID: null,
  bookOrganizationID: null,
  checkTypePerson: 0,
};

export const INITIAL_RECIVE_BANK_STATE: IReportReciveBankState = {
  all: { ...INITIAL_DATA_TABLE_TYPE },
  selected: {} as IReportReciveBank,
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
  fileUrl: '',
  fileUploadBank: INITIAL_DATA_TABLE_TYPE,
};

export const INITIAL_RECIVE_BANK_LIST: IReportReciveBankList = {
  id: '',
  idCard: '',
  passport: '',
  firstName: '',
  lastName: '',
  // bankName: '',
  bankNumber: '',
  createdDate: moment(new Date()),
  requestBankID: '',
  createdBy: '',
};

export const INITIAL_REPORT_RECIVE_BANK_VALIDATE: IReportReciveBankValidate = {
  // idCard: '',
  bookNumber: '',
  bookDate: '',
  requesterPhone: '',
  // detailBank: '',
  circumstance: '',
  inviteDate: '',
  recipientDate: '',
  commanderDate: '',
  requestDetail: '',
  requesterName: '',
};

export const INITIAL_REPORT_RECIVE_BANK_MODAL_FILE_UPLOAD_BANK: IReportReciveBankModalFileUploadBank = {
  searchKeyword: '',
  caseNo: null,
  caseName: '',
  accountNo: '',
  bankID: null,
  bankName: '',
  telephoneSMS: '',
  telephoneApplication: '',
  telephonePromptpay: '',
  citizenIDPromptpay: '',
  checkTelephoneSMS: false,
  checkTelephoneApplication: false,
  checkTelephonePromptpay: false,
  checkCitizenIDPromptpay: false,
  checkPhoneBank: false,
  idCard: '',
  titleID: null,
  titleName: '',
  firstName: '',
  lastName: '',
  fileName: '',
  attachment: null,
  ownerTitleID: null,
  ownerIDCard: '',
  ownerFirstName: '',
  ownerLastName: '',
  remark: '',
  phoneBank: '',
  imageFile: undefined,
  profileImg: undefined,
  imageFileOwner: undefined,
  profileImgOwner: undefined,
  isPrivateDelete: false,
  isPrivateDownload: false,
  isEnableDelete: null,
  isEnableDownload: null,
  formRequestBankID: '',
  //
  year: moment(new Date()),
};

export const INITIAL_REPORT_RECIVE_BANK_MODAL_FILE_UPLOAD_BANK_VALIDATE: IReportReciveBankModalFileUploadBankValidate =
  {
    accountNo: '',
    bankID: '',
    telephoneSMS: '',
    telephoneApplication: '',
    telephonePromptpay: '',
    citizenIDPromptpay: '',
    idCard: '',
    firstName: '',
    lastName: '',
    ownerIDCard: '',
    ownerFirstName: '',
    ownerLastName: '',
    phoneBank: '',
  };
