import { INITIAL_DATA_TABLE_TYPE, INITIAL_PAGINATION } from 'shared/constant';
import { IUploadFileResponse } from 'shared/interfaces/api.interface';
import { IUserManagementState } from 'store/user-management';
import {
  ICreateUserForm,
  IUserManagementSearchForm,
  IUserManagementSearchFormValidate,
  IUserManagementValidate,
} from './user-management.interface';

export const DEFAULT_CREATE_USER_FORM: ICreateUserForm = {
  birthDate: '',
  id: '',
  organizationID: null,
  divisionID: null,
  email: '',
  firstName: '',
  headQuarterID: null,
  idCard: '',
  lastName: '',
  mobilePhoneNumber: '',
  officerCode: '',
  policeStationID: null,
  positionID: null,
  subDivisionID: null,
  titleID: null,
  imageFile: undefined,
  isResetPassword: false,
  userStatusIDTemp: null,
  userStatusID: null,
  profileImgUrl: undefined,
  // teamID: [],
  teamID: null,
  departmentID: null,
  roleID: null,
  lineIDName: '',
  //
  note: '',
  fileName: '',
  attachedDocuments: [] as IUploadFileResponse[],
  newAttachments: null,
};

export const DEFAULT_USER_MANAGEMENT_VALIDATE: IUserManagementValidate = {
  titleID: '',
  firstName: '',
  lastName: '',
  officerCode: '',
  positionID: '',
  headQuarterID: '',
  divisionID: '',
  departmentID: '',
  policeStationID: '',
  subDivisionID: '',
  idCard: '',
  email: '',
  teamID: '',
  mobilePhoneNumber: '',
  roleID: '',
  organizationID: '',
  note: ''
};

export const DEFAULT_USER_MANAGEMENT_SEARCH_FORM: IUserManagementSearchForm = {
  organizationID: null,
  divisionID: null,
  firstName: '',
  headQuarterID: null,
  idCard: '',
  lastName: '',
  policeStationID: null,
  officerCode: '',
  userStatusID: null,
  userStatusIDTemp: null,
  subDivisionID: null,
  currentPage: INITIAL_PAGINATION.CURRENT_PAGE,
  pageSize: INITIAL_PAGINATION.PAGE_SIZE,
  departmentID: null,
};

export const DEFAULT_USER_STATE: IUserManagementState = {
  selected: {} as ICreateUserForm,
  all: { ...INITIAL_DATA_TABLE_TYPE },
  error: undefined,
  isButtonLoading: false,
  isPageLoading: false,
  isTableLoading: false,
};

export const DEFAULT_USER_MANAGEMENT_SEARCH_FORM_VALIDATE: IUserManagementSearchFormValidate = {
  firstName: '',
  lastName: '',
  idCard: '',
  officerCode: '',
};
